import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/hellraiser-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1987 horror movie, Hellraiser"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 27</h1>
                    <h2>Hellraiser</h2>
                    <h3>December 4, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Clive Barker // <b>Starring:</b> Doug Bradley &amp; Ashley Laurence</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/27-Hellraiser-wguest-Alaina-Urquhart-Bonus-e2cpb70" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 27, Hellraiser w/guest Alaina Urquhart"></iframe>
                        </div>
                        <p><Link to="/transcripts/hellraiser">Read the episode transcript</Link></p>
                        <p>Bryan and Dave have such sights to show you! This week they are joined by the co-host of the Morbid podcast, Alaina Urquhart, a demon to some, angel to others, to dive deep into one of her favorite movies and a real top-shelf of the horror canon, Clive Barker's Hellraiser. This movie sets the pace for the late 80's and the 90's in terms of horror, establishes Pinhead and The Cenobites as horror movie heavy hitters, and drives a stake deep into the landscape of horror movies to let the world that know Clive Barker is one of the most original voices in the history of horror fiction and movies. It also features a lot of people sticking their fingers in each others mouths. Satan's done waitin'!</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=OpEQNEHKdXg" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=OpEQNEHKdXg</OutboundLink></p>
                        <p>Morbid: <OutboundLink href="https://open.spotify.com/show/1cpyLfDHP1cNnyOb478qrt?si=c09012615b2f4fe3" target="_blank" rel="noreferrer">https://open.spotify.com/show/1cpyLfDHP1cNnyOb478qrt?si=c09012615b2f4fe3</OutboundLink></p>
                        <p><OutboundLink href="https://amzn.to/47YID9a" target="_blank" rel="noreferrer">Buy Alaina's novel, The Butcher and the Wren!</OutboundLink></p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=9ZS7eM_-jEA" target="_blank" rel="noreferrer">Unreleased Themes For Hellraiser by Coil</OutboundLink></p>

                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/silent-night-deadly-night">Next Episode</Link></li>
                        <li><Link to="/episodes/blood-rage">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)